import {getReadableTImestamp} from "@/components/lib/getReadableTImestamp";
import {createReadmeFile} from "@/components/lib/createReadmeFile";
import createZipFile from "@/components/lib/createZipFile";
import {downloadZipFile} from "@/components/lib/downloadFile";

export const downloadZippedList = async (name, list, filter) => {
  const zipFileName = `MINERVA_${name}_${getReadableTImestamp()}.zip`
  let zipFile = null
  if (list?.length) {
    const files = [createReadmeFile(filter)]

    const geoJson = {
      type: 'FeatureCollection',
      features: list.map(e => ({ // https://geojson.org/
        type: e.type,
        geometry: e.geometry,
        properties: e.properties
      }))
    }

    files.push({
      name: 'GeoJSONResults.json',
      content: JSON.stringify(geoJson)
    })

    zipFile = await createZipFile(files)
    downloadZipFile(zipFileName, zipFile)
  }
}

