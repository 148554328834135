export const createReadmeFile = filter => {
  const file = {
    name: 'README.txt',
    content: `Search results from the MINERVA road section collection

The results listed in results.json were matched using the following filter:         
`
  }
  const serializedFilter = JSON.stringify(filter, null, 4)
  file.content += serializedFilter
  return file
}
